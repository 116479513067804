import { initTim, tim as Tim } from '@/tim'
import { getUsersig } from '@/api/login'
import { getC2CHistoryMessageList } from '@/api/admin'
import { Toast } from 'vant'

const tim = {
    state: {
        needBottom: false,
        initOk: false,
        firstLoad: true,
        totalUnreadMessageCount: 0,// 未读总数
        timInfo: {
            imUserId: '',
            sdkAppId: '',
            userSig: ''
        },
        userList: [], //全部会话列表
        currentConversation: {}, // 当前会话信息
        messageListInfo: { // 当前会话消息列表
            messageList: [],
            nextReqMessageID: null,
            isCompleted: false, // 当前会话消息加载完毕
            historyIsCompleted: false // 历史消息加载完毕
        },
        // 底部浮动推荐消息
        referralMessageInfo: {
            type: '', // 推荐消息类型 自定义
            data: null // 推荐消息数据
        },
        unfinishedMessageListByconversationID: {}
    },
    mutations: {
        SET_NEED_BOTTOM: (state, needBottom) => {
            state.needBottom = needBottom
        },
        SET_TIM_STATUS: (state, initStatus) => {
            state.initOk = initStatus
        },
        SET_TOTAL_UNREAD_MESSAGE_COUNT: (state, count) => {
            state.totalUnreadMessageCount = count
        },
        SET_TIM_INFO: (state, timinfo) => {
            state.timInfo.imUserId = timinfo.imUserId
            state.timInfo.sdkAppId = timinfo.sdkAppId
            state.timInfo.userSig = timinfo.userSig
        },
        SET_USER_LIST: (state, list) => {
            const oldUserList = [...state.userList]
            console.log({ oldUserList });
            const newUserList = list || []
            const arrLenth = Math.max(oldUserList.length, newUserList.length)
            const oldUserEnum = {}
            oldUserList.forEach((oldUser, index) => {
                // 保存老数据
                oldUserEnum[oldUser.conversationID] = { user: oldUser, index }
            })
            for (let index = 0; index < arrLenth; index++) {
                const newUser = newUserList[index];
                if(!newUser) {
                    continue
                }
                const hitOldUser = oldUserEnum[newUser.conversationID]
                if (hitOldUser) {
                    oldUserList[hitOldUser.index] = newUser
                    if (!newUser.lastMessage.lastTime) {
                        newUser.lastMessage.lastTime = Date.now() / 1000
                    }
                } else if (newUser){
                    oldUserList.push(newUser)
                }
            }
            state.userList = oldUserList.sort((a, b) => b.lastMessage.lastTime - a.lastMessage.lastTime)
        },
        // 设置当前会话信息
        SET_CURRENT_CONVERSATION: (state, info) => { 
            state.currentConversation = info || {}
            // if ((state.messageListInfo.isCompleted || state.messageListInfo.messageList.length > 0) && state.firstLoad) {
            //     state.firstLoad = false
            // }
        },
        SET_FIRST_LOAD: (state, payload) => {
            state.firstLoad = payload
        },
        // 插入消息并去重
        INSERT_MESSAGE_LIST: (state, list) => {
            if(!state.currentConversation) {
                return
            }
            list.forEach((message, idx) => {
                const index = state.messageListInfo.messageList.findIndex(item => item.ID === message.ID)
                if (index >= 0) {
                    state.messageListInfo.messageList.splice(index, 1, message)
                    list[idx] = null
                }
            })
            state.messageListInfo.messageList = (state.messageListInfo.messageList || []).concat(list.filter(t => !!t))
            state.needBottom = true
        },
        // 只用于请求数据，添加更多消息
        UPDATE_MESSAGE_LIST_INFO: (state, info) => {

            if ((info.messageList || []).length > 0) {
                state.messageListInfo.messageList = (info.messageList || []).concat(state.messageListInfo.messageList)
            }
            // 历史消息中才有completed字段
            if(info.completed !== undefined) {
                state.messageListInfo.historyIsCompleted = info.completed;
            }
            // 会话消息
            if(info.isCompleted !== undefined) {
                state.messageListInfo.isCompleted = info.isCompleted
            }
            state.messageListInfo.nextReqMessageID = info.nextReqMessageID
            if (state.currentConversation.conversationID && state.firstLoad) {
                state.firstLoad = false
            }
        },
        CLEAR_CHAT_C2CINFO: (state) => {
            state.messageListInfo.nextReqMessageID = null
            state.messageListInfo.messageList = []
            state.messageListInfo.isCompleted = false
            state.currentConversation = {}
            state.firstLoad = true
        },
        UPDATE_UNFINISHED_MESSAGE_LIST: (state, [conversationID, messageList]) => { // 2n
            console.log({ conversationID, messageList });
            const currentMeesageList = state.unfinishedMessageListByconversationID[conversationID] || []
            const mergeObj = {}
            const mergeArr = [...currentMeesageList, ...messageList]
            if (mergeArr.length > 0) {
                mergeArr.forEach(message => {
                    mergeObj[message.ID] = message.status !== 'success' ? message : null
                })
            }

            state.unfinishedMessageListByconversationID[conversationID] = Object.values(mergeObj).filter(e => !!e)
        },
        SET_REFERRAL_MESSAGE_INFO: (state, info) => {
            state.referralMessageInfo = info
        },
    },
    actions: {
        async initTim({ commit, state }, data) {
            try {
                const res = await getUsersig()
                if (res.data.sdkAppId === state.timInfo.sdkAppId) {
                    return
                }
                commit('SET_TIM_INFO', res.data)
                initTim({ SDKAppID: res.data.sdkAppId, userID: res.data.imUserId, userSig: res.data.userSig})
            } catch (error) {
                console.log(error);
                return {}
            }
        },
        async getMessageList({ commit, state, dispatch }, conversationID) {
            const params = {
                conversationID: conversationID || state.currentConversation.conversationID,
                nextReqMessageID: state.messageListInfo.nextReqMessageID || null
            }
            console.log({params});
            // if(state.messageListInfo.isCompleted) {
            //     console.log('1111111111111111111111')
            //     dispatch('getHistoryMessageList')
            //     return
            // }
            if(!params.conversationID) {
                return
            }
            const messageRes = await Tim.getMessageList(params)
            Tim.setMessageRead({ conversationID: params.conversationID });
            console.log('getMessageList:', { messageRes });
            commit('UPDATE_MESSAGE_LIST_INFO', messageRes.data)

            // 会话消息加载完毕后加载一次历史消息
            if(messageRes.data.isCompleted) {
                await dispatch('getHistoryMessageList', messageRes.data.messageList.length > 0 ? 1 : 15)
            }
        },
        async getHistoryMessageList({ commit, state }, count=15) {
            const messageList = state.messageListInfo.messageList
            const params = {
                fromAccount: state.timInfo.imUserId,
                toAccount: state.currentConversation.userProfile.userID,
                msgTime: messageList[0] ? messageList[0].time : 0,
                count
            }
            const HistoryMessageRes = await Promise.all([getC2CHistoryMessageList(params), Tim.getMyProfile()])
            console.log('getHistoryMessageList:', HistoryMessageRes);
            (HistoryMessageRes[0].data.messageList || []).forEach(item => {
                item.avatar = item.flow === 'in' ? state.currentConversation.userProfile.avatar : HistoryMessageRes[1].data.avatar
            })
            commit('UPDATE_MESSAGE_LIST_INFO', HistoryMessageRes[0].data)
        },
        async getConversationProfile({ commit, state }, conversationID) {
            try {
                const friend = await Tim.getConversationProfile(conversationID)
                console.log('getConversationProfile', { friend });
                commit('SET_CURRENT_CONVERSATION', friend.data.conversation)
            } catch (error) {
                console.log(error);
                Toast.fail(error)
            }
        },
        setReferralMessageInfo({commit}, {type, data}) {
            commit('SET_REFERRAL_MESSAGE_INFO', {
                type: type || '',
                data: data || null
            });
        }
    }
}

export default tim